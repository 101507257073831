import {stage} from '../stage';
import {Cache} from 'aws-amplify/utils';
import JwtDecode from 'jwt-decode';

type ConfigShape = {
    [key: string]: {
        endpoint: string,
        region: string,
        apiKey?: string,
    }
}

const subscriptionsServiceAPI: ConfigShape = {
    prod: {
        endpoint: 'https://ofyhixe0dg.execute-api.us-east-1.amazonaws.com/Prod',
        //endpoint: 'https://api.flexi.network/subscriptions',
        region: 'us-east-1',
        apiKey: 'api-key-prd',
    },
    test: {
        endpoint: 'https://ofyhixe0dg.execute-api.us-east-1.amazonaws.com/Prod',
        region: 'us-east-1',
        apiKey: 'api-key-test',
    },
    dev: {
        endpoint: 'https://ofyhixe0dg.execute-api.us-east-1.amazonaws.com/Prod',
        region: 'us-east-1',
        apiKey: 'api-key-dev',
    },
    ide: {
        endpoint: 'https://ofyhixe0dg.execute-api.us-east-1.amazonaws.com/Prod',
        region: 'us-east-1',
        apiKey: 'api-key-cloud9',
    },
};


export const getAuthorizationHeader = async () => {
    try {
        // let currentSession = await Auth.currentSession();
        // return currentSession.getIdToken().getJwtToken();

        const lastKey = await Cache.getItem('last-key');
        // if (lastKey !== null) {}

        console.debug(lastKey);
        const userTokens = await Cache.getItem(lastKey);

        const parsed = JSON.parse(userTokens);

        console.debug('ID TOKEN IN CONFIG IS: ', parsed.idToken);
        const userData = JwtDecode(parsed.idToken);
        console.debug('USER DATA IN CONFIG IS: ', userData);

        return parsed.idToken.toString();
    } catch (e) {
        console.error(e);
    }
}

// const FlexiEbooksApiKey = 'cxzjrROctt42mBKV8QJXK2t2kvymQozF3ShPMXmU';


const apiEndpoints = {
    'FLEXI_SUBSCRIPTIONS_API': {
        endpoint: subscriptionsServiceAPI[stage].endpoint,
        region: subscriptionsServiceAPI[stage].region,
        custom_header: async () => {
            return {
                Authorization: getAuthorizationHeader(),
                //'x-api-key': FlexiEbooksApiKey
            };
        },
    },
};

export const config = apiEndpoints
